import styled from "styled-components"
import { Row, Col } from "../components/main"

export const Items = styled(Col)`
  width: 100%;
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`

export const Item = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
`

export const ItemSpan = styled.span`
  width: 80px;
  font-size: 1.4rem;
  font-weight: 600;
`

export const Span = styled.span`
  flex: 1;
  font-size: 1.4rem;
`

export const Img = styled.img`
  width: 240px;
  margin: 50px auto 0;
  @media screen and (min-width: 768px) {
    margin: 0 0 0 15%;
  }
  @media screen and (min-width: 1024px) {
    width: 30%;
  }
`
