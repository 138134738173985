import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageWrapper, Col } from "../components/main"
import { Items, Item, ItemSpan, Span, Img } from "../styles/company.js"

const Company = () => (
  <Layout>
    <SEO title="Company info" />
    <PageWrapper>
      <h1>
        Company
        <br />
        info
      </h1>
      <h3>会社概要</h3>
      <CompaniesForm />
    </PageWrapper>
  </Layout>
)

export default Company

const titles = ["会社名", "設立日", "所在地", "資本金", "代表者"]
const texts = [
  "株式会社 Lyact | Lyact Inc.",
  "2018年4月26日",
  "福岡県福岡市城南区東油山1丁目35番6ー102号",
  "1200万2750円",
  "代表取締役　古賀 聖弥",
]
const CompaniesForm = () => (
  <Items>
    <Col>
      {titles.map((title, i) => {
        return (
          <Item key={i}>
            <ItemSpan>{title}</ItemSpan>
            <Span>{texts[i]}</Span>
          </Item>
        )
      })}
    </Col>
    <Img src="https://assets-global.website-files.com/5bfd1275cc56e15ce750b18e/5d052f246a936024af93621e_Meeting%20%232.png" />
  </Items>
)
